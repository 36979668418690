import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Navigation, Footer, CopyrightLabel, SiteMap, SocialMedia } from '@fishrmn/fishrmn-components';
import { Link } from 'gatsby';
import 'semantic-ui-less/semantic.less';
import './layout.css';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFisherman {
          edges {
            node {
                       businessName 
          logo 
            }
          }
        }
      }
    `}
    render={queryData => {
      const data = queryData.allFisherman.edges[0].node;

      return (
        <div className="site-container">
          <div>
                        <Navigation
               links={ [{"pageTitle":"Home","slug":"/","internal":true},{"pageTitle":"Contact","slug":"/contact/","internal":true},{"pageTitle":"Order Online","slug":"https://ordering.chownow.com/order/27667/locations/","internal":false,"className":"chownow-order-online"}] }
               backgroundColor={ "white" }
               bordered={ false }
               centerMobileNavItems={ true }
               evenSpacing={ false }
               fixed={ false }
               header={ data.businessName }
               linksAs={ "h3" }
               logo={ data.logo }
               primaryContentPosition={ "left" }
               primaryContentCentered={ false }
               internalLinkComponent={Link}
            />
          </div>
          <div className="page-container">{children}</div>
          <Footer
             backgroundColor={ "primary" }
             horizontalAlign={ "center" }
             verticalAlign={ "middle" }
             columns={[
               <CopyrightLabel
                  company={ data.businessName }
                  phrase={ "All Rights Reserved" }
               />,
               <SiteMap
                  links={ [{"pageTitle":"Home","slug":"/","internal":true},{"pageTitle":"Contact","slug":"/contact/","internal":true},{"pageTitle":"Order Online","slug":"https://ordering.chownow.com/order/27667/locations/","internal":false,"className":"chownow-order-online"}] }
                  horizontal={ true }
                  bulleted={ false }
                  internalLinkComponent={ Link }
               />,
             ]}
          />
        </div>
      )
    }}
  />
);